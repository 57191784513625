import React from "react";
import strikelabIcon from "../../assets/strikelab_icon.svg";
import "./style.css";

export const Nav = () => (
  <div className="nav-menu section row align-middle">
    <div className="nav-logo">
      <img src={strikelabIcon} height={66} alt="Strikelab Logo" />
    </div>
    <div className="nav-brand">
      <h1 className="nav-title">strikelab</h1>
      <p className="nav-subtitle">Modern baseball pitch tracking</p>
    </div>
  </div>
);
