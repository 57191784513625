import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Cookie from "universal-cookie";
import _ from "lodash";
import mixpanel from 'mixpanel-browser';
import { isDev } from "./utils/isDev";

import "./App.css";
import * as auth from "./modules/auth/api";
import { Nav } from "./components";
import { GuardedRoute } from "./modules/auth/GuardedRoute";

import { Home } from "./modules/home";
import { AuthForm } from "./modules/auth/form";
import { BullpenDetails } from "./modules/bullpen/details";

const App = () => {
  const cookie = new Cookie();
  const [user, setUser] = useState(null);

  mixpanel.init('2277d19a93431fa639b45714ef672412', { debug: isDev });
  mixpanel.track('Visit');

  const requestLoginToken = async (email) => {
    try {
      await auth.requestLoginToken(email);
      setUser({ email, isLoggedIn: false });
    } catch (e) {
      // uhhh
    }
  };

  const validateLoginToken = async (loginToken) => {
    try {
      const { token } = await auth.validateLoginToken(user.email, loginToken);
      cookie.set("strikelab-auth-email", user.email, {
        sameSite: "lax",
        secure: true,
      });
      cookie.set("strikelab-auth-token", token, {
        sameSite: "lax",
        secure: true,
      });
      setUser({ ...user, isLoggedIn: true });
    } catch (e) {
      // uhhh
    }
  };

  useEffect(() => {
    if (!user) {
      const email = cookie.get("strikelab-auth-email");
      const token = cookie.get("strikelab-auth-token");

      if (_.isEmpty(email) || _.isEmpty(token)) {
        setUser({ email: null, isLoggedIn: false });
      } else {
        setUser({ email, isLoggedIn: true });
      }
    }
  }, [user, cookie]);

  return (
    <div id="screen">
      <Nav />

      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <AuthForm
              user={user}
              requestLoginToken={requestLoginToken}
              validateLoginToken={validateLoginToken}
            />
          </Route>

          <Route exact path="/">
            <Home />
          </Route>

          <GuardedRoute user={user} exact path="/session/:local_id">
            <BullpenDetails user={user} />
          </GuardedRoute>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
