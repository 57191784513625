import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import * as api from "./api";
import {
  SingleInlineSelector,
  MultipleInlineSelector,
  ZoneChart,
} from "../../components";

export const SnapshotOption = ({ snapshot, isSelected, setValue }) => (
  <div
    className="section row"
    style={{
      backgroundColor: isSelected(snapshot) ? "#4a4a4a" : "#4a4a4a40",
    }}
    onClick={() => (isSelected(snapshot) ? setValue(null) : setValue(snapshot))}
  >
    <div>
      <ZoneChart pitches={snapshot.pitches} width={66} />
    </div>
    <div className="small-column">{snapshot.comments}</div>
  </div>
);

const percent = (interest, total) => _.round((interest / total) * 100, 1);
const averageVelocity = (vals) =>
  !_.isEmpty(vals) ? _.round(_.sumBy(vals, "velocity") / vals.length, 1) : "-";

export const PitchStatRow = ({ pitches, label, color }) => {
  const total = _.size(pitches);
  const numStrikes = _.size(_.filter(pitches, (pitch) => pitch.is_strike));
  const numHitSpot = _.size(_.filter(pitches, (pitch) => pitch.is_hit_spot));
  const velocities = _.map(pitches, (pitch) =>
    !_.isEmpty(pitch.velocity) ? pitch.velocity : null
  );

  const strikePercentage = total > 0 ? percent(numStrikes, total) : "0";
  const hitSpotPercentage = total > 0 ? percent(numHitSpot, total) : "0";
  const averageVelocityValue = total > 0 ? averageVelocity(velocities) : "-";

  return (
    <div className="row" style={{ color: color ? color : "#000" }}>
      <span className="label small-column">{`${total}`}</span>
      <span className="label small-column">{label}</span>
      <span className="label small-column">{`${strikePercentage}%`}</span>
      <span className="label small-column">{`${hitSpotPercentage}%`}</span>
      <span className="label small-column">{`${averageVelocityValue}`}</span>
    </div>
  );
};

export const PitchMetrics = ({ pitches, pitchTypes }) => (
  <>
    <div className="row">
      <span className="label bold small-column">#</span>
      <span className="label bold small-column">Pitch type</span>
      <span className="label bold small-column">Strikes</span>
      <span className="label bold small-column">Hit spot</span>
      <span className="label bold small-column">Avg. Velo</span>
    </div>
    <PitchStatRow pitches={pitches} label="Total" />
    {_.map(pitchTypes, (pitchType) => (
      <PitchStatRow
        key={pitchType.id}
        pitches={_.filter(
          pitches,
          (pitch) => pitch.pitch_type.id === pitchType.id
        )}
        label={pitchType.name}
        color={pitchType.color}
      />
    ))}
  </>
);

const FilteringZoneChart = ({ pitches, snapshots, pitchTypes }) => {
  const [selectedPitchTypes, setSelectedPitchTypes] = useState([]);
  const [selectedIsStrike, setSelectedIsStrike] = useState(null);
  const [selectedIsHitSpot, setSelectedIsHitSpot] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);

  const filterPitches = () => {
    if (!_.isEmpty(selectedSnapshot)) {
      return selectedSnapshot.pitches;
    }

    let filteredPitches = pitches;

    if (!_.isEmpty(selectedPitchTypes)) {
      filteredPitches = _.filter(filteredPitches, (pitch) =>
        _.includes(
          _.map(selectedPitchTypes, (option) => option.value),
          pitch.pitch_type.id
        )
      );
    }

    if (selectedIsStrike !== null) {
      filteredPitches = _.filter(
        filteredPitches,
        (pitch) => pitch.is_strike === selectedIsStrike.value
      );
    }

    if (selectedIsHitSpot !== null) {
      filteredPitches = _.filter(
        filteredPitches,
        (pitch) => pitch.is_hit_spot === selectedIsHitSpot.value
      );
    }

    return filteredPitches;
  };

  return (
    <div className="center row">
      <div className="section column">
        <div>
          <MultipleInlineSelector
            label="Pitch Type"
            getOptionText={(option) => option.label}
            selected={selectedPitchTypes}
            setValue={(option) => setSelectedPitchTypes(option)}
            options={[
              { id: "0", label: "All", value: null },
              ..._.map(pitchTypes, (pitchType) =>
                pitchType
                  ? {
                      id: pitchType.id,
                      label: pitchType.name || "huh",
                      value: pitchType.id || 0,
                      color: pitchType.color,
                    }
                  : { id: "2222", label: "asdfasdfasdf", value: 3444 }
              ),
            ]}
          />
          <SingleInlineSelector
            label="Accuracy"
            getOptionText={(option) => option.label}
            selected={selectedIsStrike}
            setValue={(option) => setSelectedIsStrike(option)}
            options={[
              { id: "0", label: "All", value: null },
              { id: "1", label: "Strike", value: true },
              { id: "2", label: "Ball", value: false },
            ]}
          />
          <SingleInlineSelector
            label="Precision"
            getOptionText={(option) => option.label}
            selected={selectedIsHitSpot}
            setValue={(option) => setSelectedIsHitSpot(option)}
            options={[
              { id: "0", label: "All", value: null },
              { id: "1", label: "Hit spot", value: true },
              { id: "2", label: "Missed spot", value: false },
            ]}
          />
        </div>
        <div className="section align-left">
          <span>Snapshots</span>
          <SingleInlineSelector
            renderOption={(snapshot, isSelected, setValue) => (
              <SnapshotOption
                key={snapshot.id}
                snapshot={snapshot}
                isSelected={isSelected}
                setValue={setValue}
              />
            )}
            isSelected={(snapshot) =>
              !_.isEmpty(selectedSnapshot) &&
              snapshot.id === selectedSnapshot.id
            }
            setValue={(snapshot) => setSelectedSnapshot(snapshot)}
            options={snapshots}
          />
        </div>
      </div>
      <div className="section column">
        <div>
          <PitchMetrics
            pitches={filterPitches()}
            pitchTypes={pitchTypes}
          />
        </div>
        <div>
          <ZoneChart pitches={filterPitches()} />
        </div>
      </div>
    </div>
  );
};

export const BullpenDetails = (props) => {
  const { local_id } = useParams();
  const [session, setSession] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!session) {
      api
        .requestSession(local_id)
        .then((s) => setSession(s))
        .catch((e) => setError(e));
    }
  }, [session, local_id]);

  const renderSessionDetails = () => {
    const { snapshots, pitches, player } = session;
    const pitchTypes = _.uniqBy(
      _.map(pitches, (pitch) => pitch.pitch_type),
      "id"
    );

    return (
      <>
        <div className="section">
          <h3>
            {session.is_bullpen ? "Bullpen" : "Game"} on{" "}
            {moment(session.created_at).format("dddd, MMMM Do YYYY, h:mm a")}
          </h3>
          <br />
          <h3>{`#${player.number} ${player.name}`}</h3>
          <h4>{player.team.name}</h4>
        </div>

        <FilteringZoneChart
          snapshots={snapshots}
          pitches={pitches}
          pitchTypes={pitchTypes}
        />
      </>
    );
  };

  const renderScreen = () => {
    if (session && !error) {
      return renderSessionDetails();
    } else if (!session && error) {
      return <div>{error.detail}</div>;
    }

    return (
      <div>
        <p>Loading</p>
      </div>
    );
  };

  return <section className="section">{renderScreen()}</section>;
};
