import React from "react";
import _ from "lodash";
import "./style.css";

export const SingleInlineSelector = (props) => (
  <div className="selector">
    <span className="label">{props.label}</span>

    <div className="options">
      {_.map(props.options, (option) => {
        const isSelected = props.isSelected
          ? props.isSelected(option)
          : (_.isEmpty(props.selected) && option.value === null) ||
            (!_.isEmpty(props.selected) && props.selected.id === option.id);

        return props.renderOption ? (
          props.renderOption(option, props.isSelected, props.setValue)
        ) : (
          <button
            key={option.id}
            className="item"
            style={
              isSelected
                ? {
                    backgroundColor: option.color
                      ? `${option.color}`
                      : "#4a4a4a",
                  }
                : {
                    backgroundColor: option.color
                      ? `${option.color}40`
                      : "#4a4a4a40",
                  }
            }
            onClick={() =>
              option.value === null
                ? props.setValue(null)
                : props.setValue(isSelected ? null : option)
            }
          >
            {props.getOptionText(option)}
          </button>
        );
      })}
    </div>
  </div>
);

export const MultipleInlineSelector = (props) => (
  <div className="selector">
    <span className="label">{props.label}</span>

    <div className="options">
      {_.map(props.options, (option) => {
        const isSelected =
          (_.isEmpty(props.selected) && option.value === null) ||
          !_.isEmpty(
            _.find(props.selected, (selected) => selected.id === option.id)
          );

        return (
          <button
            key={option.id}
            className="item"
            style={
              isSelected
                ? {
                    backgroundColor: option.color
                      ? `${option.color}`
                      : "#4a4a4a",
                  }
                : {
                    backgroundColor: option.color
                      ? `${option.color}40`
                      : "#4a4a4a40",
                  }
            }
            onClick={() => {
              if (option.value === null) {
                props.setValue([]);
              } else {
                props.setValue(
                  isSelected
                    ? _.filter(props.selected, (item) => item.id !== option.id)
                    : [...props.selected, option]
                );
              }
            }}
          >
            {props.getOptionText(option)}
          </button>
        );
      })}
    </div>
  </div>
);
