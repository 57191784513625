import React, { useState } from "react";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";

export const AuthForm = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const requestLoginToken = async (e) => {
    e.preventDefault();
    await props.requestLoginToken(email);
  };

  const validateLoginToken = async (e) => {
    e.preventDefault();
    await props.validateLoginToken(token);
  };

  const renderEmailForm = () => (
    <form onSubmit={requestLoginToken}>
      <label>
        Email:
        <input
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </label>

      <input type="submit" value="Request token" />
    </form>
  );

  const renderTokenForm = () => (
    <form onSubmit={validateLoginToken}>
      <label>
        Token:
        <input
          type="text"
          onChange={(e) => setToken(e.target.value)}
          value={token}
        />
      </label>

      <input type="submit" value="Validate" />
    </form>
  );

  const renderForm = () => {
    const { user } = props;
    if (_.isEmpty(user) || (_.isEmpty(user.email) && !user.isLoggedIn)) {
      return renderEmailForm();
    } else if (!user.isLoggedIn) {
      return renderTokenForm();
    }

    const { from } = location.state || { from: { pathname: "/" } };

    history.replace(from);
  };

  return <>{renderForm()}</>;
};
