import React from "react";
import _ from "lodash";
import { Redirect, Route } from "react-router-dom";

export const GuardedRoute = ({ children, user, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      !_.isEmpty(user) && user.isLoggedIn ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )
    }
  />
);
