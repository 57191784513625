import React from "react";
import _ from "lodash";
import "./styles.css";
import zoneChartImage from "../../assets/ZoneChart.png";
import { withSize } from "react-sizeme";

export const computeLocation = (pitch, zoneSize) => {
  const { pitch_location_x, pitch_location_y, zone_width, zone_height } = pitch;
  const widthRatio = zoneSize.width / zone_width;
  const heightRatio = zoneSize.height / zone_height;

  return {
    pitchLocationX: pitch_location_x * widthRatio,
    pitchLocationY: pitch_location_y * heightRatio,
  };
};

export const PitchDot = ({ label, pitchType, location, size }) => (
  <div
    className="pitch-dot"
    style={{
      right: location.pitchLocationX,
      top: location.pitchLocationY + size / 2,
      backgroundColor: pitchType.color,
    }}
  >
    {label}
  </div>
);

export const ZoneChart = withSize({ monitorHeight: false })((props) => {
  const { width } = props.size;
  const height = (width * 4) / 3;

  return (
    <div style={{ width: props.width ? props.width : "auto" }}>
      <img
        src={zoneChartImage}
        style={{ width, height }}
        alt="Zone chart background"
      />
      {!_.isEmpty(props.pitches)
        ? _.map(props.pitches, (pitch) => (
            <PitchDot
              key={pitch && pitch.id ? pitch.id : 0}
              label={
                pitch && pitch.pitch_number ? `${pitch.pitch_number}` : null
              }
              location={computeLocation(pitch, { width, height })}
              pitchType={pitch.pitch_type}
              size={20}
            />
          ))
        : null}
    </div>
  );
});
