import _ from "lodash";
import Cookie from "universal-cookie";
import { isDev } from "./isDev";

const BASE_URL = isDev ? "http://api.strikelab.local" : "https://strikelab.io";

const defaultOptions = {
  method: "GET",
  headers: { "Content-Type": "application/json" },
  body: null,
};

export const request = (
  path,
  options = defaultOptions,
  auth = false,
  queryParams = {},
  useBase = true
) => {
  var url = useBase ? `${BASE_URL}${path}` : path;
  const payload = { ...defaultOptions, ...options };

  if (!_.isEmpty(queryParams)) {
    const queryString = _.join(
      _.map(queryParams, (param, value) => `${param}=${value}`),
      "&"
    );
    url = `${url}?${queryString}`;
  }

  if (!_.isEmpty(options.body)) {
    payload.body = JSON.stringify(payload.body);
  }

  if (auth) {
    const cookie = new Cookie();
    const token = cookie.get("strikelab-auth-token");
    if (_.isEmpty(token)) {
      return Promise.reject("Auth token not set");
    }

    payload.headers = { ...payload.headers, Authorization: `Token ${token}` };
  } else {
    delete payload.headers.Authorization;
  }

  return fetch(url, payload).then(async (response) => {
    let data;
    try {
      data = await response.json();
    } catch (e) {
      data = null;
    }

    switch (response.status) {
      case 200:
        return data || {};
      case 401:
        // logout
        break;
      default:
        return Promise.reject(data);
    }
  });
};
