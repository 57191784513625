import { request } from "../../utils/http";

export const requestSession = (local_id) =>
  request(
    `/v1/pitcher-outings/${local_id}/`,
    {
      method: "GET",
    },
    true
  );
