import React from "react";
import trackingStepsGraphic from "../../assets/graphics/tracking-steps.jpg";
import filtersGraphic from "../../assets/graphics/filters.jpg";
import snapshotsGraphic from "../../assets/graphics/snapshots.jpg";
import { AppStoreBadge, PlayStoreBadge } from "../../components/AppStoreBadge";

export const Home = (props) => (
  <>
    <section className="section center">
      <p>
        Strikelab is a baseball pitch tracking app developed to provide an
        intuitive and valuable way for coaches and players to track pitching
        performance over time.
      </p>
    </section>

    <section className="section center">
      <p>Download it today!</p>

      <div className="row align-middle">
        <AppStoreBadge />
        <PlayStoreBadge />
      </div>
    </section>

    <section className="section">
      <h2>Benefits</h2>

      <div className="section">
        <p>
          Tracking pitches in the app is a simple process, which lets you focus
          on coaching the player instead of trying to fill out a paper chart. No
          account needed.
        </p>
        <img
          src={trackingStepsGraphic}
          alt="Tracking pitches: 1. select a pitch type; 2. select the pitch location; 3. input the pitch results"
        />
      </div>

      <div className="section">
        <p>
          Pitch charts have live-updating filters. Get the most advanced
          breakdown of pitch data possible by mixing and matching conditions,
          even during tracking, for instant, actionable feedback for your
          players.
        </p>
        <img
          src={filtersGraphic}
          alt="Live updating filters: select different configurations of pitch types and results for analysis"
        />
      </div>

      <div className="section">
        <p>
          Every pitch you track is automatically synced to all your logged-in
          devices. Share sessions, players, and even teams with anybody, and
          changes will sync to their devices, too.
          <br />
          <br />
          Capture snapshots of the pitch chart under certain filters and attach
          a comment to preserve feedback and note patterns in your data.
        </p>
        <img
          src={snapshotsGraphic}
          alt="Snapshots: capture feedback based on filter configurations"
        />
      </div>
    </section>

    <section className="section">
      <h2>Pricing</h2>
      <div className="section">
        <p>In order to support development of the app, we've added a simple pricing structure aimed at providing the most flexibility possible for individual players and parents. Out of the box, you are allowed to record pitches for up to 3 players. After that, additional players can be purchased as follows:</p>
        <ul>
          <li>+1 Player - $1.99</li>
          <li>+3 Players - $4.99</li>
          <li>+10 Players - $9.99</li>
        </ul>
        <p>Only players for which you have recorded pitching sessions (either bullpens or games) will affect your allotment. You can record an unlimited number of sessions per player in your allotment and additional players, once purchased, do not expire.</p>
        <p>Additionally, we offer a monthly subscription that un-limits the number of players that can be recorded for.</p>
        <ul>
          <li>Basic subscription - $9.99/month</li>
          {/* <li>Premium subscription - not yet available.</li> */}
        </ul>
        <p>The basic subscription simply removes the need to purchase additional players; you can record unlimited sessions for unlimited players while you're subscribed. Recorded sessions won't disappear if your subscription lapses, but you will need to either re-subscribe or purchase enough players to continue recording.</p>
      </div>
    </section>

    {/* <section className="section center">
        <AuthForm
          email={props.user.email}
          token={props.user.token}
          requestLoginToken={props.requestLoginToken}
          validateLoginToken={props.validateLoginToken}
          afterAuthenticate={afterAuthenticate}
        />
      </section> */}

    {/* feature roadmap, user feedback */}
    <section className="section row">
      <div className="column">
        <h3>Roadmap</h3>
        <div>
          <label>
            <input type="checkbox" checked disabled readOnly /> Bullpen
            recording
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" checked disabled readOnly /> Session sync
            &amp; share
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" checked disabled readOnly /> Game recording
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" disabled readOnly /> More in-depth player and
            team reports
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" disabled readOnly /> User experience and
            feedback work
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" disabled readOnly /> Video
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" disabled readOnly /> Other recording modes
          </label>
        </div>
      </div>
    </section>

    <section className="section">
      <div>
        <p>
          If you need help or want to suggest a feature, email me at{" "}
          <a href="mailto:info@strikelab.io">info@strikelab.io</a>.
        </p>
      </div>
    </section>
  </>
);
